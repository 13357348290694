@font-face {
  font-family: antropos;
  src: url(fonts/antrf___.ttf);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* padding-top: 70px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  min-height: 50vh;
  max-width: 100%;
}

.agenda_card {
  max-width: 30rem;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid silver;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.wrap-reverse li {
  background: rgb(65, 112, 156);
}

.flex-item {
  width: min-content;
  margin: 0px;
  min-width: 50%;
  max-width: 100%;
  color: rgb(65, 112, 156);
  font-weight: bold;
  font-size: 3em;
  text-align: center;
}

.tile {
  margin-bottom: 0px;
  padding-top: 0px;
  max-width: 100%;
  max-height: 50%;
  min-width: 50%;
  color: rgb(65, 112, 156);
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  /* aspect-ratio: 1 / 1; */
  object-fit: cover;
}

.text-tile {
  margin-top: 8%;
}

.card-text {
  font-size: 1.1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.35em;
  font-family: antropos;
  text-transform: uppercase;
  font-weight: 700;
  color: rgb(65, 112, 156);
  font-weight: bold;
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header {
  color: rgb(65, 112, 156);
}
.card-title {
  color: rgb(65, 112, 156);
}

/* .card {
  min-height: 40vh;
} */

.col-lg-6 {
  padding: 0px;
}

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 10;
  width: 99%;
  top: 0;
  background-color: rgb(65, 112, 156);
}

.Banner {
  z-index: -1;
  width: 100%;
  margin: 0px;
  margin-bottom: -6%;
}

.banner-img {
  top: 0;
  padding: 0px;
  border: 2px solid rgb(65, 112, 156);
  width: 100%;
  height: 120%;
  bottom: -100px;
  object-fit: cover;
}

.galleryWrap {
  /* border-radius: 2%; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
}
.galleryWrap .galleryimg {
  max-width: 300px;
  cursor: pointer;
}
.galleryWrap .galleryimg img {
  border-radius: 5%;
  max-width: 100%;
  max-height: 200px;
}

.galleryWrap .galleryimg img:hover {
  transform: scale(0.98);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
  font-size: xx-large;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  border-radius: 5%;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  border-radius: 5%;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

a {
  color: rgb(65, 112, 156);
}

.wrap-text-around-image {
  border-radius: 5%;
  float: right;
  max-width: 200px;
}

label,
.form-group,
Form {
  text-align: left;
  align-items: left;
}

iframe {
  width: 100%;
  height: 450px;
}
